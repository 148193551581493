<template>
  <div class="blognotes-top tl f6 relative pa3 pa5-ns overflow-hidden">
    <nav class="bb b--light-gray pv3 tc" aria-label="Main">
      <span class="link pointer blue hover-mid-gray mh2 pv1"
         v-cloak @click="filter_by_year(null)">all</span>
      <span class="link pointer blue hover-mid-gray mh2 pv1"
         v-for="year in years" :key="year.end" @click="filter_by_year(year)">{{ year.display }}</span>
    </nav>

    <li class="blognotes-note note" v-for="note in notes" :key="note.id">
      <time class="blognotes-date f6 light-silver">{{ format_date(note.created_on) }}</time>
      <span v-if="note.title"> - </span>
      <span class="blognotes-title f6 gray b"
            v-if="note.title">{{ note.title }}</span>
      <span v-if="user"> | </span>
      <span class="blognotes-delete f7 red underline pointer dim"
            v-if="user" @click="delete_note(note)">delete</span>
      <div class="blognotes-tags f6 db">
        <li class="blognotes-tag blue hover-mid-gray mt1"
           v-for="tag in note.tags" :key="tag">{{ tag }}</li>
      </div>
      <div class="blognotes-content markdown-body note"
          v-html="render(note.content)">
      </div>
    </li>
    <div class="blognotes-button pointer mv3"
         v-if="notes.length && notes_more" @click="load_more">
      <span>load more...</span>
    </div>
  </div>
</template>

<script>
import app from "../firebaseInit";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { query, doc, collection, where, orderBy, limit, startAfter,
         getDocs, updateDoc, serverTimestamp } from "firebase/firestore";
import markdown_it from 'markdown-it';
import moment from 'moment-timezone';
import _ from "lodash";

const auth = getAuth(app);
const db = getFirestore(app);
const md = markdown_it({ breaks: true, html: true });

export default {
  name: 'BlogNotes',
  data() {
    return {
      notes: [],
      notes_more: true,
      notes_per_page: 20,
      user: null,
      loading: false,
      years_before: 4,
      years: [],
      years_filter: null,
    }
  },
  created() {
    this.load_notes();
  },
  mounted() {
    auth.onAuthStateChanged((user) => {
      if (user) this.user = user;
    });
  },
  methods: {
    load_notes() {
      this.loading = true;

      const constraints = this.compute_constraints_by_year();
      constraints.push(where('deleted_on', '==', null));
      constraints.push(orderBy('created_on', 'desc'));
      constraints.push(limit(this.notes_per_page));

      const q = query(collection(db, 'notes'), ...constraints);
      getDocs(q).then((snapshot) => {
        this.notes = _.map(snapshot.docs, doc => { return {id: doc.id, snapshot: doc, ...doc.data()}; })
        this.loading = false;
        this.notes_more = (this.notes.length == this.notes_per_page);

        if (this.years.length == 0 && this.notes.length > 0) {
          const year = moment(this.notes[0].created_on.seconds * 1000).year();
          for (let i = 0; i < this.years_before; ++i) {
            this.years.push({start: year-i, end: year-i+1, display: year-i});
          }
          this.years.push({end: year-this.years_before+1, display: 'earlier'});
        }
      });
    },
    load_more() {
      if (!this.notes) return;
      if (this.loading) return;

      this.loading = true;

      const constraints = this.compute_constraints_by_year();
      constraints.push(where('deleted_on', '==', null));
      constraints.push(orderBy('created_on', 'desc'));
      constraints.push(startAfter(this.notes[this.notes.length-1].snapshot));
      constraints.push(limit(this.notes_per_page));

      const q = query(collection(db, 'notes'), ...constraints);
      getDocs(q).then((snapshot) => {
        const notes = _.map(snapshot.docs, doc => { return {id: doc.id, snapshot: doc, ...doc.data()}; })
        this.notes.push(...notes);
        this.loading = false;
        this.notes_more = (notes.length == this.notes_per_page);
      })
    },
    filter_by_year(year) {
      this.years_filter = year;
      this.load_notes();
    },
    compute_constraints_by_year() {
      if (!this.years_filter) return [];

      const year = this.years_filter;

      const constraints = []
      if (year.start) {
        const start_date = new Date(`${year.start}-01-01`);
        constraints.push(where('created_on', '>=', start_date));
      }
      const end_date = new Date(`${year.end}-01-01`);
      constraints.push(where('created_on', '<',  end_date));

      return constraints;
    },
    delete_note(note) {
      if (!this.user) return;
      const ref = doc(db, 'notes', note.id);
      updateDoc(ref, {deleted_on: serverTimestamp()}).then(() => {
        this.notes = _.filter(this.notes, (n) => { return n.id !== note.id; });
      });
    },
    format_date(d) {
      return moment.tz(d.seconds*1000, 'Asia/Shanghai')
                   .format('MMM DD YYYY HH:mm');
    },
    render(content) {
      return md.render(content);
    }
  }
}
</script>
