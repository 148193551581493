<template>
  <div v-if="user" @dragover.prevent @drop.prevent
       class="blogwriter-top bb b--light-gray tl f6 relative pa3 pa5-ns overflow-hidden">
    <div class="blogwriter-file-list mv2 monospace"
         v-if="files.length">
      <p class="f6 b black-80 db mv1">files</p>
      <ul v-for="file in files" :key="file">
        <li><span>/{{ file.fullPath }}</span></li>
      </ul>
    </div>
    <form class="blogwriter-text-editor"
         @drop="dropFile" @submit.prevent>
      <label for="title"
             class="f6 b black-80 db mv1">title</label>
      <div class="cf mb2">
        <input id="title" type="text"
               class="input-reset ba b--black-80 pa1 w-100 w-80-ns br1-ns br--left-ns monospace"
               :value="title" @input="update_title"
        >
        <input type="submit" value="Submit"
               class="button-reset ba pointer pointer pa1 w-100 w-20-ns br1-ns br--right-ns monospace"
               @click="do_submit">
      </div>

      <label for="content"
             class="f6 b black-80 db mb1">content</label>
      <textarea id="content"
                class="db border-box hover-black w-100 h5 b--black br1-ns pa1 monospace"
                style="resize: vertical;"
                :value="content" @input="update"/>

    </form>
  </div>
</template>

<script>
import app from "../firebaseInit";
import { getAuth } from "firebase/auth";
import { getFirestore, serverTimestamp } from "firebase/firestore";
import { collection, doc, addDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import moment from 'moment';
import _ from "lodash";

const auth = getAuth(app);
const storage = getStorage(app);
const db = getFirestore(app);

export default {
  name: 'BlogWriter',
  emits: ['content-submitted'],
  data() {
    return {
      title: '',
      user: null,
      files: [],
      tags: [],
    }
  },
  mounted() {
    auth.onAuthStateChanged((user) => {
      if (user && user.uid == 'F9PDNowrfBTTb9nolSqfA2FPzfg1') {
        this.user = user;
      }
    });
  },
  methods: {
    update: _.debounce(function(e) { this.content = e.target.value; }, 300),
    update_title: _.debounce(function(e) { this.title = e.target.value; }, 300),
    dropFile(e) {
      const now = new Date();
      const prefix = 'uploads/' + moment(now).format('YYYY-MM/DD-HHmmss-');

      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i];
        const upload_path = `${prefix}${file.name}`;

        const storage_ref = ref(storage, upload_path);
        uploadBytes(storage_ref, file).then((snapshot) => {
          console.log('uploaded!', snapshot);
          this.files.push(snapshot.ref);
        })
      }
    },
    async do_submit() {
      const data = {
        title: this.title,
        content: this.content,
        tags: this.tags,
        author: this.user.displayName,
        updated_on: serverTimestamp(),
        deleted_on: null,
      };

      if (this.note_id) {
        await updateDoc(doc(db, 'notes', this.note_id), data);
      } else {
        data.created_on = serverTimestamp();
        await addDoc(collection(db, 'notes'), data);
      }

      this.$emit('content-submitted');

      this.title    = '';
      this.content  = '';
      this.files    = [];
      this.tags     = [];
    }
  }
}

</script>
