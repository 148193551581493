<template>
  <div class="mw7 bg-white mt4 mb3 center br2-ns bt bb ba-ns b--light-gray">
    <FirebaseAuth />
    <BlogWriter @content-submitted="load_notes" />
    <BlogNotes ref="blogNotes" />
  </div>
</template>

<script>

import FirebaseAuth from './components/FirebaseAuth.vue'
import BlogWriter from './components/BlogWriter.vue'
import BlogNotes from './components/BlogNotes.vue'

export default {
  name: 'App',
  components: {
    FirebaseAuth,
    BlogWriter,
    BlogNotes,
  },
  data() {
    return { }
  },
  methods: {
    load_notes() {
      this.$refs.blogNotes.load_notes();
    }
  }
}

</script>

<style>
@import './assets/style.css';
</style>
