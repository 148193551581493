import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyC8GCF_rrFY_rg5eejwb6nBShlc00Z91_8",
  authDomain: "all-my-love-to-long-ago.firebaseapp.com",
  projectId: "all-my-love-to-long-ago",
  storageBucket: "all-my-love-to-long-ago.appspot.com",
  messagingSenderId: "935149629673",
  appId: "1:935149629673:web:fb8280d5341a439596c078",
  measurementId: "G-2QCMX5EX1N"
};

export default initializeApp(firebaseConfig);
