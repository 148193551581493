<template>
  <div class="bottom-1 right-1 fixed mb1">
    <section id="firebaseui-auth-container"></section>
    <div class="center mw4 mv3">
      <button type="button" id="btn-sign-out"
              class="f6 pointer dim bn shadow-1 ph3 pv2 mb2 dib b black bg-white w-100"
        v-show="signout" @click="doSignout()">Sign out</button>
    </div>
  </div>
</template>

<script>
import app from "../firebaseInit";
import firebase from 'firebase/compat/app';
import { getAuth } from "firebase/auth";
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

const auth = getAuth(app);

export default {
  name: 'FirebaseAuth',
  data() {
    return {
      signout: false,
    };
  },
  mounted() {
    const ui = new firebaseui.auth.AuthUI(auth);
    auth.onAuthStateChanged((user) => {
      console.log('user', user);
      if (user) {
        this.signout = true;
        ui.reset();
      } else {
        var uiConfig = {
          signInFlow: 'popup',
          signInSuccessUrl: "/",
          signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID]
        };
        ui.start("#firebaseui-auth-container", uiConfig);
      }
    })
  },
  methods: {
    doSignout() {
      auth.signOut().then(() => {
        console.log('Signed out');
        this.signout = false;
      }).catch((error) => {
        console.log('Sign out error', error);
      });
    }
  }
}

</script>
